import React from 'react';

function Dashboard() {
  return (
    <div>
      <h2>Admin Dashboard</h2>
      <p>Welcome, Administrator! Here is your dashboard.</p>
      {/* 可添加更多内容 */}
    </div>
  );
}

export default Dashboard;
